// @flow
import { graphql, useStaticQuery } from 'gatsby';
import each from 'lodash/each';
import filter from 'lodash/filter';

import useCurrentLocale from '~plugins/i18n/hooks/useCurrentLocale';

import type {
  Query,
  PrismicNewsEntryConnection,
  PrismicPressEntryConnection,
  PrismicUseCaseConnection,
  PrismicWhitePaperConnection,
} from '~schema';

export type UseAutomaticRelatedContent = {
  allPrismicNewsEntry?: ?PrismicNewsEntryConnection,
  allPrismicPressEntry?: ?PrismicPressEntryConnection,
  allPrismicUseCase?: ?PrismicUseCaseConnection,
  allPrismicWhitePaper?: ?PrismicWhitePaperConnection,
};

const query = graphql`
  query UseAutomaticRelatedContent {
    allPrismicNewsEntry {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        uid
        data {
          title {
            text
            html
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          publication_date
          body {
            ... on Node {
              id
            }
            ... on PrismicNewsEntryBodyText {
              slice_type
              primary {
                slice_text {
                  text
                  html
                }
              }
            }
          }
        }
      }
    }
    allPrismicPressEntry {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        uid
        data {
          title {
            text
            html
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          publication_date
          body {
            ... on Node {
              id
            }
            ... on PrismicPressEntryBodyText {
              slice_type
              primary {
                slice_text {
                  text
                  html
                }
              }
            }
          }
        }
      }
    }
    allPrismicUseCase {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        uid
        data {
          title {
            text
            html
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          use_case_logo {
            alt
            localFile {
              id
              childImageSharp {
                fluid(maxWidth: 80, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          publication_date
          body {
            ... on Node {
              id
            }
            ... on PrismicUseCaseBodyText {
              slice_type
              primary {
                slice_text {
                  text
                  html
                }
              }
            }
          }
        }
      }
    }
    allPrismicWhitePaper {
      nodes {
        id
        lang
        type
        href
        first_publication_date
        data {
          name {
            text
            html
          }
          title {
            text
            html
          }
          description {
            text
            html
          }
          document_link {
            url
          }
          background_image {
            alt
            fluid(maxWidth: 300) {
              ...GatsbyPrismicImageFluid
            }
          }
          zoho_listkey
        }
      }
    }
  }
`;

export default function useAutomaticRelatedContent(): UseAutomaticRelatedContent {
  const locale = useCurrentLocale();
  const contentTypes = useStaticQuery<Query>(query);
  let queryResults = {};
  each(contentTypes, (contentType, key) => {
    queryResults = {
      ...queryResults,
      [key]: {
        nodes: filter(
          contentType.nodes,
          node => node.lang === locale.prismicLocale,
        ),
      },
    };
  });
  return queryResults;
}
