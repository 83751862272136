// @flow
import type { UseAutomaticRelatedContent } from '../hooks/useAutomaticRelatedContent';

export default function getSliceContentType(
  slice_content_type?: ?string,
  contentData?: ?UseAutomaticRelatedContent,
) {
  return (
    (slice_content_type === 'news entry' &&
      contentData?.allPrismicNewsEntry?.nodes) ||
    (slice_content_type === 'press entry' &&
      contentData?.allPrismicPressEntry?.nodes) ||
    (slice_content_type === 'use case' &&
      contentData?.allPrismicUseCase?.nodes) ||
    (slice_content_type === 'white paper' &&
      contentData?.allPrismicWhitePaper?.nodes) ||
    null
  );
}
