// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';

import NodeBody from '~plugins/prismic/components/NodeBody';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import Header from '~components/Header';
import RelatedContentSlice from '~components/slices/RelatedContentSlice';
import AutomaticRelatedContentSlice from '~components/slices/AutomaticRelatedContentSlice';
import FooterContactForm from '~components/FooterContactForm';
import useSetAppBarState from '~components/AppBar/hooks/useSetAppBarState';
import type { PrismicKnowUsPage } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicKnowUsPage>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    overflow: 'hidden',
  },
  bodySection: {},
});

const KnowUsPage = ({ classes, node }: Props) => {
  useSetAppBarState({
    overlayColor: node?.data?.overlay_color,
    backgroundImage: node?.data?.background_image,
  });
  return (
    <div className={classes.root}>
      <Header
        componentVariant="simple"
        title={node?.data?.title}
        subtitle={node?.data?.subtitle}
        backgroundImage={node?.data?.background_image}
        overlayColor={node?.data?.overlay_color}
      />
      <NodeBody
        node={node}
        components={{
          related_content: RelatedContentSlice,
          automatic_related_content: AutomaticRelatedContentSlice,
        }}
        className={classes.bodySection}
      />
      <FooterContactForm currentPage={node?.data?.name?.text} />
    </div>
  );
};

export default compose(
  withNodePage<PrismicKnowUsPage, *>({
    getNode: data => data?.prismicKnowUsPage,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'KnowUsPage' }),
)(KnowUsPage);

export const query = graphql`
  query KnowUsPageQuery($prismicId: ID) {
    prismicKnowUsPage(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        background_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        overlay_color
        body {
          ... on Node {
            id
          }
          ... on PrismicKnowUsPageBodyRelatedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
                document {
                  ... on PrismicWhitePaper {
                    id
                    data {
                      name {
                        text
                        html
                      }
                      title {
                        text
                        html
                      }
                      description {
                        html
                        text
                      }
                      document_link {
                        url
                      }
                      zoho_listkey
                      zoho_lead_source
                      zoho_industry
                    }
                  }
                }
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicKnowUsPageBodyAutomaticRelatedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_content_type
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
              slice_background_color
            }
          }
        }
      }
    }
  }
`;
