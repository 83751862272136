// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Fade from 'react-reveal/Fade';

import NodeLink from '~plugins/prismic/components/NodeLink';
import newDate from '~helpers/newDate';
import StyledCard from '~components/StyledCard';
import WhitePaperCard from '~components/WhitePaperCard';
import type {
  PrismicNewsEntry,
  PrismicPressEntry,
  PrismicUseCase,
  PrismicWhitePaper,
} from '~schema';

export type ClassKey =
  | 'carousel'
  | 'carouselWrapper'
  | 'carouselSlider'
  | 'topRightControls'
  | 'buttonBase'
  | 'leftArrow'
  | 'rightArrow'
  | 'itemGridItem'
  | 'itemCard'
  | 'itemWhitePaperCard'
  | 'itemCardImage'
  | 'cardTitle'
  | 'paginationDots';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  items:
    | ?Array<PrismicNewsEntry>
    | ?Array<PrismicPressEntry>
    | ?Array<PrismicUseCase>
    | ?Array<PrismicWhitePaper>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  carousel: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  carouselSlider: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-2),
    },
  },
  itemGridItem: {
    height: '100%',
    margin: theme.spacing(2),
    '& div.react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  itemWhitePaperCard: {
    height: 'auto',
  },
  itemCardImage: {
    [theme.breakpoints.up('sm')]: {
      height: 220,
    },
    height: 160,
  },
  cardTitle: {
    '& > *': {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  topRightControls: {
    position: 'absolute',
    top: theme.spacing(-8),
    right: 0,
  },
  buttonBase: {
    width: 48,
    height: 48,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[400],
    },
  },
  leftArrow: {},
  rightArrow: {},
  carouselWrapper: {
    overflow: 'visible !important',
  },
  paginationDots: {
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    '& .carousel__dot': {
      width: 18,
      height: 18,
      borderRadius: '50%',
      padding: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: 'rgba(255,255,255,0.2)',
      margin: theme.spacing(1),
      '&:disabled': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
});

const AutomaticContentCarousel = ({
  items,
  // type,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const slidesToShow = (isXsDown && 1) || (isMdDown && 2) || 3;
  const intl = useIntl();

  const renderTopRightControls = (
    <div className={classes.topRightControls}>
      <ButtonBack className={classes.buttonBase}>
        <ArrowLeftIcon className={classes.leftArrow} />
      </ButtonBack>
      <ButtonNext className={classes.buttonBase}>
        <ArrowRightIcon className={classes.rightArrow} />
      </ButtonNext>
    </div>
  );

  const cardClasses = React.useMemo(
    () => ({
      image: classes.itemCardImage,
    }),
    [classes],
  );
  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const sortedBlogEntries = sortBy(items, [
    entry => entry?.data?.publication_date || entry?.first_publication_date,
  ])
    .reverse()
    .slice(0, 6);

  const renderedItems = React.useMemo(() => {
    return compact(
      map(sortedBlogEntries, (item, index: number) => {
        const publication_date = newDate(
          // $FlowFixMe --> Reason: `data` is missing in `PrismicDocument`
          item?.data?.publication_date || item?.first_publication_date,
        );
        return item ? (
          <Slide
            key={index}
            index={index}
            aria-label={`slide-${index}`}
            className={classes.itemGridItem}
          >
            <Fade cascade>
              {item && item.type === 'white_paper' ? (
                <WhitePaperCard
                  title={item?.data?.title}
                  description={
                    item && item.data && item.data.description
                      ? item.data.description
                      : undefined
                  }
                  image={item?.data?.background_image}
                  link={
                    item && item.data && item.data.document_link
                      ? item.data.document_link
                      : undefined
                  }
                  whitePaper={item}
                  className={classnames(
                    classes.itemCard,
                    classes.itemWhitePaperCard,
                  )}
                  classes={(cardClasses, { title: classes.cardTitle })}
                />
              ) : (
                <StyledCard
                  node={
                    item.type === 'news_entry' ||
                    item.type === 'press_entry' ||
                    item.type === 'use_case'
                      ? // $FlowFixMe --> Reason: string literal `PrismicWhitePaper` is incompatible with  string literal
                        item
                      : undefined
                  }
                  title={item?.data?.title}
                  image={item?.data?.background_image}
                  publicationDate={
                    item.type === 'news_entry' || item.type === 'press_entry'
                      ? intl.formatDate(publication_date, options)
                      : null
                  }
                  className={classes.itemCard}
                  cardActionAreaProps={{
                    component: NodeLink,
                    disableRipple: true,
                    node: item,
                  }}
                  classes={(cardClasses, { title: classes.cardTitle })}
                  {...(item?.type === 'use_case'
                    ? // $FlowFixMe --> Reason: don't get the right data type
                      { useCaseLogo: item.data?.use_case_logo }
                    : null)}
                />
              )}
            </Fade>
          </Slide>
        ) : null;
      }),
    );
  }, [items]);

  return (
    <CarouselProvider
      totalSlides={renderedItems.length}
      naturalSlideHeight={0}
      naturalSlideWidth={0}
      visibleSlides={slidesToShow}
      isIntrinsicHeight
      className={classnames(classes.carousel, className)}
      {...props}
    >
      <Slider
        aria-label="slider"
        className={classes.carouselWrapper}
        classNameTray={classes.carouselSlider}
      >
        {renderedItems}
      </Slider>
      {isXsDown ? <DotGroup className={classes.paginationDots} /> : null}
      {!isXsDown ? renderTopRightControls : null}
    </CarouselProvider>
  );
};

AutomaticContentCarousel.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AutomaticContentCarousel' })(AutomaticContentCarousel);
