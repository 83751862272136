// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';
import SliceTitle from '~components/SliceTitle';
import FieldLink from '~plugins/prismic/components/FieldLink';

import AutomaticRegularContentGrid from './AutomaticRegularContentGrid';
import AutomaticContentCarousel from './AutomaticContentCarousel';
import useAutomaticRelatedContent from './hooks/useAutomaticRelatedContent';
import getSliceContentType from './helpers/getSliceContentType';
import type { Props } from './types';
import styles from './styles';

const AutomaticRelatedContentSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  const sliceType = data?.primary?.slice_content_type;

  const contentData = useAutomaticRelatedContent();
  const sliceContent = getSliceContentType(sliceType, contentData);

  return !sliceContent?.length ? null : (
    <Component className={classnames(classes.root, className)} {...props}>
      <SliceTitle
        title={data?.primary?.slice_title}
        description={data?.primary?.slice_description}
        className={classes.sliceTitle}
      />
      {sliceContent && sliceContent.length > 3 ? (
        <AutomaticContentCarousel items={sliceContent} />
      ) : (
        <AutomaticRegularContentGrid items={sliceContent} />
      )}
      {data.primary?.slice_link ? (
        <Button
          component={FieldLink}
          field={data.primary?.slice_link}
          variant="text"
          color="primary"
          size="large"
          endIcon={<ArrowForward color="primary" />}
          disableTouchRipple
          aria-label={data.primary?.slice_link_label?.text}
          className={classes.archiveLink}
        >
          {data.primary?.slice_link_label?.text}
        </Button>
      ) : null}
    </Component>
  );
};

AutomaticRelatedContentSlice.defaultProps = {
  className: undefined,
};

export const StyledAutomaticRelatedContentSlice = withStyles<*, *, Props>(
  styles,
  { name: 'AutomaticRelatedContentSlice' },
)(AutomaticRelatedContentSlice);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledAutomaticRelatedContentSlice>,
>()(StyledAutomaticRelatedContentSlice);
