// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import Fade from 'react-reveal/Fade';

import NodeLink from '~plugins/prismic/components/NodeLink';
import StyledCard from '~components/StyledCard';
import WhitePaperCard from '~components/WhitePaperCard';
import newDate from '~helpers/newDate';
import type {
  PrismicNewsEntry,
  PrismicPressEntry,
  PrismicUseCase,
  PrismicWhitePaper,
} from '~schema';

export type ClassKey =
  | 'itemGridContainer'
  | 'itemGridItem'
  | 'itemCard'
  | 'itemWhitePaperCard'
  | 'cardTitle';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  items:
    | ?Array<PrismicNewsEntry>
    | ?Array<PrismicPressEntry>
    | ?Array<PrismicUseCase>
    | ?Array<PrismicWhitePaper>,
  className?: string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  itemGridContainer: {},
  itemGridItem: {
    '& div.react-reveal': {
      height: '100%',
    },
  },
  itemCard: {
    width: '100%',
    height: '100%',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgba(0,0,0,0.1), 0px 6px 10px 0px rgba(0,0,0,0.1), 0px 1px 14px 0px rgba(0,0,0,0.1)',
    },
  },
  itemWhitePaperCard: {
    height: 'auto',
  },
});

const AutomaticRelatedContentGrid = ({
  items,
  // type,
  className,
  // component,
  classes,
  ...props
}: Props): React.Node => {
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const intl = useIntl();

  const options = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const sortedBlogEntries = sortBy(items, [
    entry => entry?.data?.publication_date || entry?.first_publication_date,
  ]).reverse();

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(sortedBlogEntries, (item, index: number) => {
          const publication_date = newDate(
            // $FlowFixMe --> Reason: `data` is missing in `PrismicDocument`
            item?.data?.publication_date || item?.first_publication_date,
          );
          return item ? (
            <Grid
              item
              key={index}
              md={4}
              xs={12}
              className={classes.itemGridItem}
            >
              <Fade cascade>
                {item && item.type === 'white_paper' ? (
                  <WhitePaperCard
                    title={item?.data?.title}
                    description={
                      item && item.data && item.data.description
                        ? item.data.description
                        : undefined
                    }
                    image={item?.data?.background_image}
                    link={
                      item && item.data && item.data.document_link
                        ? item.data.document_link
                        : undefined
                    }
                    whitePaper={item}
                    classes={{ title: classes.cardTitle }}
                    className={classnames(
                      classes.itemCard,
                      classes.itemWhitePaperCard,
                    )}
                  />
                ) : (
                  <StyledCard
                    node={
                      item.type === 'news_entry' ||
                      item.type === 'press_entry' ||
                      item.type === 'use_case'
                        ? // $FlowFixMe --> Reason: string literal `PrismicWhitePaper` is incompatible with  string literal
                          item
                        : undefined
                    }
                    title={item?.data?.title}
                    image={item?.data?.background_image}
                    publicationDate={
                      item.type === 'news_entry' || item.type === 'press_entry'
                        ? intl.formatDate(publication_date, options)
                        : null
                    }
                    classes={{ title: classes.cardTitle }}
                    className={classes.itemCard}
                    cardActionAreaProps={{
                      component: NodeLink,
                      disableRipple: true,
                      node: item,
                    }}
                    {...(item?.type === 'use_case'
                      ? // $FlowFixMe --> Reason: don't get the right data type
                        { useCaseLogo: item.data?.use_case_logo }
                      : null)}
                  />
                )}
              </Fade>
            </Grid>
          ) : null;
        }),
      ),
    [items],
  );

  return (
    <Grid
      container
      spacing={isSmUp ? 4 : 5}
      alignItems="stretch"
      className={classnames(classes.itemGridContainer, className)}
      {...props}
    >
      {renderedItems}
    </Grid>
  );
};

AutomaticRelatedContentGrid.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'AutomaticRelatedContentGrid' })(AutomaticRelatedContentGrid);
